/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap'); */
@import url('https://adagiomedical.com/849587');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		/* font-family: 'Raleway', sans-serif; */
		font-family: Gotham, sans-serif;
	}
}
